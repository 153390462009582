/* Common styles for Signup and Login */

.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(to right, #007bff, #ff8c00);
  margin: 0 auto;
}

.signup-box,
.login-box {
  width: 400px;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.form-group {
  margin-top: 10px;
}

label {
  display: block;
  font-weight: bold;
}

.form-group input {
  width: 100%;
  padding: 14px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 5px 0;
  background-color: white;
  color: black;
}

.submit-button,
.login-button {
  margin: 15px;
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:hover,
.login-button:hover {
  background-color: #0056b3;
}

.vessel-type-options label {
  display: inline-block;
  margin-right: 10px;
  font-weight: 300;
}

/* Responsive styles */
@media (max-width: 768px) {
  .signup-container,
  .login-container {
    height: auto;
  }

  .signup-box,
  .login-box {
    width: 100%;
    margin: 0 10px;
  }
}

/* Additional styles for Signup.css */

.signup-image {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  display: block;
}

.vessel-type-options {
  display: inline-block;
  margin-top: 5px;
}

/* Additional styles for Login.css */

.login-container {
  background-color: #f4f4f4;
}

.login-image {
  width: 100%;
  height: auto;
}

.error-message {
  color: red;
  font-weight: bold;
  margin: 10px 0;
}

.loader-container {
  color: black;
}
