:root {
  --background-dark: black;
  --text-dark: white;
  --button-dark: grey;
  --button-disabled-dark: #555;

  --background-light: white;
  --text-light: black;
  --button-light: lightgray;
  --button-disabled-light: #ddd;
}

.floating-box {
  background-color: var(--background-dark);
  color: var(--text-dark);
}

.button {
  background-color: var(--button-dark) !important;
  color: var(--text-dark) !important;
}

.button:disabled {
  background-color: var(--button-disabled-dark) !important;
  color: #999 !important;
}

[data-theme="light"] .floating-box {
  background-color: var(--background-light);
  color: var(--text-light);
}

[data-theme="light"] .button {
  background-color: var(--button-light) !important;
  color: var(--text-light) !important;
}

[data-theme="light"] .button:disabled {
  background-color: var(--button-disabled-light) !important;
  color: #aaa !important;
}

.ion-modal {
  --background: var(--background-dark);
}

[data-theme="light"] .ion-modal {
  --background: var(--background-light);
}

.closeButton {
  color: var(--text-dark);
}

[data-theme="light"] .closeButton {
  color: var(--text-light);
}

.titleStyle {
  color: var(--text-dark);
}

[data-theme="light"] .titleStyle {
  color: var(--text-light);
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleStyle {
  margin-right: auto;
  font-size: 16px;
  font-weight: bold;
}

.closeButton {
  display: none !important;
}

.error-text {
  color: red;
  font-size: 12px;
  margin-top: 4px;
  display: block;
}

.floating-box {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 380px;
  background-color: black;
  padding: 20px;
  z-index: 1000;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.settings-icon {
  display: block;
}

.router__buttons__wrapper {
  display: flex;
  flex-direction: column;
}

.button {
  background-color: grey !important;
  color: #fff !important;
  border: none !important;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  width: 100%;
}

.button:disabled {
  background-color: #555 !important;
  color: #999 !important;
  cursor: not-allowed;
}

.divider {
  display: none;
}

.ion-select {
  flex: 1.5 !important;
  min-width: 120px !important;
  background-color: #808080 !important;
  color: #fff !important;
  padding: 8px !important;
  border-radius: 4px !important;
  text-align: center !important;
  border: none !important;
  margin-left: 5px !important;
  appearance: none;
}

.ion-item {
  margin-top: 1vw !important;
  display: flex;
  align-items: center;
  gap: 10px;
}

.ion-label {
  font-size: 1vw !important;
  color: white;
  flex: 1;
}

.ion-select-option {
  background-color: #444 !important;
  color: white !important;
  font-size: 16px !important;
  padding: 5px !important;
}

.ion-select-option1 {
  background-color: #444 !important;
  color: white !important;
  font-size: 16px !important;
  padding: 5px !important;
}

.ion-select-option[selected] {
  background-color: #666 !important;
  font-weight: bold !important;
}

.ion-modal {
  --width: 150vw;
  --max-width: 600px;
  --height: 45vh;
  --border-radius: 0;
  --background: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  overflow-y: auto;

  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}
.ion-modal.show-modal {
  transform: translateX(0);
}
.childOne {
  display: flex !important;
  align-items: center !important;
  gap: 10px !important;
  margin-top: 10px !important;
}

.vessel-label {
  font-size: 1vw !important;
  color: white !important;
  flex: 1 !important;
}

.childOne select {
  flex: 1.5 !important;
  min-width: 80px !important;
  background-color: #808080 !important;
  color: #fff !important;
  padding: 8px !important;
  border-radius: 4px !important;
  text-align: center !important;
  border: none !important;
  appearance: none !important;
}

@media screen and (max-width: 1024px) {
  .floating-box {
    width: 250px;
  }
  .ion-select {
    min-width: 110px !important;
    padding: 7px !important;
  }

  .ion-label {
    font-size: 0.8rem !important;
  }

  .ion-select-option {
    font-size: 14px !important;
  }
  .childOne {
    gap: 8px !important;
    margin-top: 8px !important;
  }
  .vessel-label {
    font-size: 1vw !important;
  }
  .childOne select {
    padding: 7px !important;
  }
}

@media screen and (max-width: 768px) {
  .closeButton {
    background-color: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #fff;
    transition: color 0.3s;
    font-weight: bold;
    display: flex !important;
  }

  .closeButton:hover {
    color: #aaa;
  }
  .titleStyle {
    margin-right: 20rem;
  }
  .floating-box {
    width: 30% !important;
    right: 25% !important;
    left: 25% !important;
    top: 10% !important;
    max-height: 50% !important;
  }
  .ion-select {
    min-width: 100px !important;
    padding: 6px !important;
  }

  .ion-label {
    font-size: 1rem !important;
  }

  .ion-item {
    margin-top: 2vw !important;
    flex-direction: column;
    align-items: flex-start;
  }

  .ion-select-option {
    font-size: 12px !important;
  }
  .ion-modal {
    --width: 80vw !important;
    --max-width: none;
  }
  .childOne {
    flex-direction: column !important;
    gap: 6px !important;
    margin-top: 6px !important;
  }
  .vessel-label {
    font-size: 14px !important;
    width: 100% !important;
    text-align: center !important;
  }
  .childOne select {
    width: 100% !important;
    padding: 6px !important;
  }
  .subTitle {
    font-size: 2.5vw !important;
  }
}

@media screen and (max-width: 1200px) {
  .titleStyle {
    margin-right: 28rem;
  }
  .childOne {
    gap: 8px !important;
    margin-top: 8px !important;
  }
  .vessel-label {
    font-size: 1vw !important;
  }
  .childOne select {
    padding: 7px !important;
  }
}

@media screen and (max-width: 480px) {
  .titleStyle {
    margin-right: 12rem;
  }
  .closeButton {
    display: flex !important;
    background-color: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #fff;
    transition: color 0.3s;
    font-weight: bold;
  }

  .closeButton:hover {
    color: #aaa;
  }
  .ion-select {
    min-width: 90px !important;
    padding: 5px !important;
  }

  .ion-label {
    font-size: 2vw !important;
  }

  .ion-select-option {
    font-size: 10px !important;
  }
  .childOne {
    flex-direction: column !important;
    gap: 4px !important;
    margin-top: 4px !important;
  }
  .vessel-label {
    font-size: 12px !important;
  }
  .childOne select {
    width: 100% !important;
    padding: 4px !important;
    font-size: 12px !important;
  }
  .subTitle {
    font-size: 2.5vw !important;
  }
}

@media (max-width: 767px) {
  .floating-box {
    display: none;
  }
  .vessel-label {
    font-size: 13px !important;
  }
  .childOne select {
    padding: 5px !important;
  }
  .subTitle {
    font-size: 2.5vw !important;
  }
}

[data-theme="light"] .ion-label,
[data-theme="light"] .vessel-label {
  color: var(--text-light) !important;
}

[data-theme="light"] .titleStyle {
  color: var(--text-light) !important;
}

[data-theme="light"] .floating-box {
  background-color: var(--background-light) !important;
  color: var(--text-light) !important;
}

[data-theme="light"] .floating-box .titleStyle {
  color: var(--text-light) !important;
}

[data-theme="light"] .childOne select {
  background-color: var(--button-light) !important;
  color: var(--text-light) !important;
}

[data-theme="light"] .ion-modal {
  --background: var(--background-light) !important;
}

[data-theme="light"] .ion-modal .ion-label,
[data-theme="light"] .ion-modal .vessel-label {
  color: var(--text-light) !important;
}

[data-theme="light"] .button {
  background-color: var(--button-light) !important;
  color: var(--text-light) !important;
}

[data-theme="light"] .button:disabled {
  background-color: var(--button-disabled-light) !important;
  color: #aaa !important;
}

[data-theme="light"] .closeButton {
  color: var(--text-light) !important;
}

[data-theme="light"] .error-text {
  color: red !important;
}
.subTitle {
  display: block;
  font-size: 1vw;
}
