.home-action-button,
.home-logout-button,
.home-layers-button,
.home-settings-button,
.home-recenter-button {
  margin: 10px;
}

ion-fab-button {
  --border-radius: 10px;
  --background: #f7f7f6;
  --background-activated: #f7f7f6;
  --background-hover: #f7f7f6;
  --background-activated-hover: #f7f7f6;
  --box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
@media (min-width: 769px) {
  .home-settings-button {
    display: none !important;
  }
}

.ion-button2 {
  padding: 0% !important;
  border-radius: 5% !important;
}
