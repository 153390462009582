.layer-modal-list-container {
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  margin: 0 auto !important;
  padding: 15px 20px !important;
  justify-content: center !important;
  align-items: center !important;
}

.layer-modal-list-container ion-item {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
}

.layer-modal-button .ion-button {
  width: 100% !important;
  max-width: 95% !important;
  background-color: #007acc;
  color: white !important;
  font-family: Arial, sans-serif !important;
  font-size: clamp(0.875rem, 2vw + 0.25rem, 1rem) !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
  line-height: 1.3rem !important;
  text-align: center !important;
  margin: 10px auto !important;
  padding: 0 !important;
}

.ion-button1 {
  padding: 0 !important;
}

.layer-modal-button ion-button:hover {
  background-color: #005fa3;
}

.layer-modal-button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.layer-modal-header,
.timestamp-modal-header {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 1rem !important;
  border-bottom: 1px solid #e0e0e0 !important;
  text-align: center !important;
  color: var(--ion-text-color, #000) !important;
}

.layer-modal-title,
.timestamp-modal-header h1 {
  font-family: Arial, sans-serif !important;
  font-size: clamp(1rem, 2vw + 0.5rem, 1.25rem) !important;
  font-weight: bold !important;
  color: var(--ion-text-color, #000) !important;
  margin-bottom: 1rem !important;
}

.layer-modal-content {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 15px !important;
}

ion-modal#layer-modal,
ion-modal#timestamp-modal {
  --height: 40% !important;
  --width: 30% !important;
  --border-radius: 12px !important;
  --background: var(--ion-background-color, #fff) !important;
}

.timestamp-modal ion-item {
  padding: 0.75rem !important;
  text-align: center !important;
  font-size: 0.875rem !important;
  color: var(--ion-text-color, #000) !important;
}

.timestamp-modal ion-item:hover {
  background-color: var(--ion-background-color, #e0e0e0) !important;
}

.timestamp-modal-footer {
  display: flex !important;
  justify-content: center !important;
  padding: 1rem !important;
}

@media screen and (max-width: 1200px) {
  ion-modal#layer-modal {
    --width: 35% !important;
  }
  ion-modal#timestamp-modal {
    --width: 40% !important;
  }
  .layer-modal-button .ion-button {
    max-width: 90% !important;
    font-size: clamp(0.65rem, 1vw, 0.7rem) !important;
    padding: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  ion-modal#layer-modal {
    --width: 50% !important;
  }
  ion-modal#timestamp-modal {
    --width: 60% !important;
  }
  .layer-modal-button .ion-button {
    max-width: 85% !important;
    font-size: clamp(0.75rem, 2vw, 0.875rem) !important;
    padding: 0 !important;
  }
}

@media screen and (max-width: 480px) {
  ion-modal#layer-modal {
    --width: 70% !important;
  }
  ion-modal#timestamp-modal {
    --width: 80% !important;
  }
  .layer-modal-button .ion-button {
    max-width: 80% !important;
    font-size: clamp(0.525rem, 1vw + 0.15rem, 0.5rem) !important;
    padding: 0 !important;
  }
}
.ion-button1 {
  padding: 0 !important;
}
